import type { AuthenticatedUser } from 'src/auth/types';

import { useMemo, useCallback, useState, useEffect } from 'react';
import { isBrowser } from 'src/hooks/use-is-browser';
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

const useUserDetailsRetreiver = (): [
  AuthenticatedUser | null,
  boolean,
  () => Promise<AuthenticatedUser | null>,
] => {
  const [fetchedDetails, setFetchedDetails] = useState(false);
  const [ready, setReady] = useState(false);
  const [details, setDetails] = useState<AuthenticatedUser | null>(null);

  const handleGetUserDetails = useCallback<() => Promise<AuthenticatedUser | null>>(async () => {
    if (details || fetchedDetails) {
      return null;
    }
    setFetchedDetails(true);
    try {
      if (!isBrowser()) {
        setDetails(null);
        setReady(true);
        return null;
      }
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;

      setDetails(clientPrincipal);
      return clientPrincipal;
    } catch (error) {
      // console.error(`Error retrieving user details: ${error}`);
      setDetails(null);
    } finally {
      setReady(true);
    }
    return null;
  }, [details, fetchedDetails, setDetails, setFetchedDetails, setReady]);

  return [details, ready, handleGetUserDetails];
};

function MsalProvider({ children }: Props) {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [details, ready, retriveUserDetails] = useUserDetailsRetreiver();

  const handleLogin = useCallback(async () => {
    const userInfo = await retriveUserDetails();
    if (userInfo && userInfo.userRoles && !userInfo.userRoles.includes('member')) {
      window.location.href = '/no-access/';
    } else {
      window.location.href = '/';
    }
  }, [retriveUserDetails]);

  const handleLogout = useCallback(() => {
    window.location.href = '/logout';
  }, []);

  const handleRoleValidation = useCallback(
    (role: string) => {
      if (details === null || !details.userRoles?.length) {
        return false;
      }
      return details.userRoles!.filter((s) => s === role).length > 0;
    },
    [details]
  );

  useEffect(() => {
    retriveUserDetails();
  }, [retriveUserDetails]);

  useEffect(() => {
    if (ready) {
      setAuthenticated(!!details);
    }
  }, [ready, details, setAuthenticated]);

  // ----------------------------------------------------------------------

  const memoizedValue = useMemo(
    () => ({
      user: {
        ...details,
        photoURL: null,
      },
      method: 'msal',
      loading: false,
      authenticated: isAuthenticated,
      unauthenticated: !isAuthenticated,
      //
      hasRole: handleRoleValidation,
      login: handleLogin,
      logout: handleLogout,
    }),
    [handleRoleValidation, handleLogin, handleLogout, isAuthenticated, details]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

// ----------------------------------------------------------------------

export function AuthProvider({ children }: Props) {
  return <MsalProvider>{children}</MsalProvider>;
}
