exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-login-google-tsx": () => import("./../../../src/pages/.auth/login/google.tsx" /* webpackChunkName: "component---src-pages-auth-login-google-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-success-tsx": () => import("./../../../src/pages/login-success.tsx" /* webpackChunkName: "component---src-pages-login-success-tsx" */),
  "component---src-pages-no-access-tsx": () => import("./../../../src/pages/no-access.tsx" /* webpackChunkName: "component---src-pages-no-access-tsx" */),
  "component---src-pages-simple-page-slug-tsx": () => import("./../../../src/pages/{SimplePage.slug}.tsx" /* webpackChunkName: "component---src-pages-simple-page-slug-tsx" */),
  "component---src-templates-den-page-tsx": () => import("./../../../src/templates/den-page.tsx" /* webpackChunkName: "component---src-templates-den-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-restricted-page-tsx": () => import("./../../../src/templates/restricted-page.tsx" /* webpackChunkName: "component---src-templates-restricted-page-tsx" */),
  "slice---src-layouts-footer-tsx": () => import("./../../../src/layouts/footer.tsx" /* webpackChunkName: "slice---src-layouts-footer-tsx" */),
  "slice---src-layouts-header-tsx": () => import("./../../../src/layouts/header.tsx" /* webpackChunkName: "slice---src-layouts-header-tsx" */)
}

